<template>
  <div class="projects" :class="processed ? 'projects--load' : ''">
    <div class="projects__team" v-for="(team) in teams">
      <h4>{{ team.name }}</h4>
      <div class="projects__item flex aic jcsb"
           v-for="(space, index) in team.spaces"
           v-show="space.remote_id"
           :class="space.linked ? 'is-used' : ''">
        <h5>{{ space.project_name }}</h5>
        <button @click.prevent="connectProject($event,index,space)" :disabled="submitted || space.linked">
          {{ space.linked ? 'connected' : 'connect' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/_services/api.service";
import {mapActions, mapGetters, mapMutations} from 'vuex';
import TasksRightSidebar from "@/components/tasks-right-sidebar/TasksRightSidebar";

export default {
  name: 'ProjectsConnect',
  data() {
    return {
      teams: [],
      processed: true,
      submitted: false,
    }
  },
  components: {
    TasksRightSidebar,
  },
  created() {
    if (this.teams.length > 0) {
      return this.teams;
    }

    let initialSettings = this.getInitialSettings;
    apiService.get('team-integrations/get_spaces', {
      team_id: initialSettings.team.team_id
    } )
      .then( response => {
        this.teams = response.data;
        return response.data;
      } )
      .catch( error => {
        this.$store.dispatch( 'teamsIntegration/fetchIntegration', this.getActiveTeam )
          .then( response => {
            if ( response.data.integration_id > 0 ) {
              this.fetchTeams();
            }
          } );
      } )
      .finally( () => {
        this.processed = false;
      } );
  },
  methods: {
    ...mapActions(
      {
        fetchProjects: 'projects/fetchProjects',
        fetchTeams: 'teams/fetchTeams',
      }
    ),
    connectProject(e, index, project) {
      let btn = e.target;
          btn.classList.add('is-load');

      this.submitted = true;

      let formData = new FormData;
          formData.append('project_id', this.getActiveProject.project_id);
          formData.append('team_id', this.getActiveProject.team_id);
          formData.append('remote_id', project.remote_id);
          formData.append('project_prefix', project.remote_custom_id);

      apiService.post('/projects/update', formData)
        .then( response => {
          if (response.data.message === 'ok') {
            this.$emit('updateStatus', true);
            this.fetchProjects();
          }
          return response.data;
        } )
        .catch( error => {
          this.response = error.response.data;
        } )
        .finally( () => {
          this.submitted = false;
          btn.classList.remove('is-load');
        } );
    }
  },
  computed: {
    ...mapGetters(
      {
        getActiveTeam: 'teams/getActiveTeam',
        getActiveProject: 'projects/getActiveProject',
        getInitialSettings: 'user/getInitialSettings',
      }
    ),
  },
}
</script>

<style lang="postcss">
@import "styles.css";
</style>
