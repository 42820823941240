<template>
  <div @click="toggleProject()" class="project-item db w100 m0"
       :class="projectIsActive ? 'project-item--active' : ''">
    <div class="project-item_container flex jcsb aic w100">
      <div class="project-item__main-content-wrap">
        <div class="project-item__icon flex jcc aic">
          <span class="project-item__symbol flex jcc aic w100 h100" :style="{'background': project.background}">
            {{ projectShortName }}
          </span>
        </div>
        <div class="project-item__label flex jcfs aic">
          <span class="project-item__label-text" :title="project.project_name">
            {{ project.project_name }}
          </span>
        </div>
      </div>
      <div class="project-item__tasks-count flex aic jcc">{{ project.tasks_count }}</div>
      <div class="dot-menu" @click.stop="editProject(project)"></div>
    </div>
  </div>
  <Modal :isVisible="modalEditProjectIsVisible" :isClosable="true"
         @close="modalEditProjectIsVisible = false">
    <ModalEditProject :project="project"/>
  </Modal>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Modal from '@/components/modal/Modal';
import ModalEditProject from '@/components/modal-edit-project/ModalEditProject';
import {commonHelper} from '@/_helpers/common-helper';

export default {
  name: 'ProjectItem',
  data() {
    return {
      modalEditProjectIsVisible: false,
      editedProjectId: 0,
    };
  },
  props: {
    project: Object,
  },
  methods: {
    ...mapMutations(
        {
          setActiveProjectId: 'projects/setActiveProjectId',
          setActiveTaskId: 'tasks/setActiveTaskId',
          setTasks: 'tasks/setTasks',
        },
    ),
    ...mapActions(
        {
          fetchTasks: 'tasks/fetchTasks',
        },
    ),
    toggleProject() {
      if (this.project.project_id !== this.getActiveProject.project_id && !this.isFetching) {
        this.setTasks([]);
        this.$router.replace({name: 'Project', params: {projectPrefix: this.project.project_prefix}});
        this.setActiveTaskId(null);
        this.setActiveProjectId(this.project.project_id);
        this.fetchTasks();
      }
    },
    editProject(project) {
      this.modalEditProjectIsVisible = true;
    },
  },
  computed: {
    ...mapGetters(
        {
          getActiveProject: 'projects/getActiveProject',
          isFetching: 'tasks/isFetching'
        },
    ),
    projectIsActive() {
      return this.project.project_id === this.getActiveProject.project_id;
    },
    projectShortName() {
      return commonHelper.getShortName(this.project.project_name);
    },
  },
  components: {
    Modal,
    ModalEditProject,
  },
};
</script>

<style lang="postcss">
@import "styles.css";
</style>
