<template>
  <div v-if="getProjects" class="sidebar__projects db w100 m0">
    <div class="sidebar__projects-list flex fdc jcfs aifs w100">
      <ProjectItem v-for="projectItem in getProjects.projects" :project="projectItem"></ProjectItem>
    </div>
  </div>
</template>

<script>
import ProjectItem from '../project-item/ProjectItem';
import {mapGetters} from 'vuex';

export default {
  name: 'Projects List',
  components: {
    ProjectItem,
  },
  computed: {
    ...mapGetters(
        {
          getProjects: 'projects/getProjects'
        }
    ),
  },
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>
