<template>
  <div class="task-list__container flex fdc" :style="anyTaskIsActive() ? 'margin-right: 0px; padding-right: 0px;' : ''">
    <div v-if="isFetching" class="loading mv1 text-center">Loading, please wait</div>
    <div v-if="!getTasks.tasks && !isFetching && isFetched && getActiveStatusFilter !== 3 && getActiveStatusFilter !== 2" class="mv1 text-center">
      Nothing found. Please use the instruction below. We believe you will love it! 🔥
    </div>
    <div v-if="!isFetching && isFetched && getActiveStatusFilter === 2" class="mv1 text-center">
      No tickets in backlog yet 🙈
    </div>
    <div v-if="!isFetching && isFetched && getActiveStatusFilter === 3" class="mv1 text-center">
      This shiny new feature will be here soon 🚀
    </div>
    <div v-if="singleTaskIsNotFound" class="mv1 text-center">
      Task with this ID is not found
    </div>
    <template v-if="task_is_found">
      <div @click="toggleTask(item)" class="task-list__item-wrap flex pointer" :class="taskIsActive(item) ? 'active' : ''"
           v-for="item in getTasks.tasks">
        <div class="task-list__item w100 flex aic">
          <div class="task-list__item-project-name task-list__item-child">
            {{ item.project.prefix }}-{{ item.id }}
          </div>
          <div class="task-list__item-author flex aic task-list__item-child">
            <UserAvatar :user="item.user" :size="32"/>
            <span class="ml1">{{ getUserName(item) }}</span>
          </div>
          <div class="task-list__item-role task-list__item-child">
            <span>{{ item.role.label }}</span>
          </div>
          <div class="task-list__item-link task-list__item-child">
            <a target="_blank" :href="item.browser_info.link">{{ visibleLink(item.browser_info.link) }}</a>
          </div>
          <div class="task-list__item-comment-count task-list__item-child">
            {{ item.comments_count }}
          </div>
          <div class="task-list__item-created-at task-list__item-child">
            <span>{{ item.created_at_human }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import {commonHelper} from '@/_helpers/common-helper';
import UserAvatar from '@/components/user-avatar/UserAvatar';

export default {
  name: 'TasksList',
  components: {
    UserAvatar,
  },
  data() {
    return {
      task_is_found: true,
    };
  },

  methods: {
    ...mapMutations(
        {
          setActiveProjectId: 'projects/setActiveProjectId',
          setActiveTaskId: 'tasks/setActiveTaskId',
          setSingleTaskIsNotFound: 'tasks/setSingleTaskIsNotFound'
        },
    ),
    getUserName(item) {
      const user = item.user;
      if ( user && user.first_name && user.last_name) {
          return `${user.first_name} ${user.last_name}`;
      }
      return 'John Doe';
    },
    visibleLink(link) {
      if (commonHelper.isValidHttpUrl(link)) {
        return `${ commonHelper.getDomain(link) }${ commonHelper.getPath(link) }`;
      }
      return '';
    },
    toggleTask(task) {
      this.setSingleTaskIsNotFound(false);
      const selectedTask = this.getActiveTask;
      const currentTask = task ? task : selectedTask;

      this.$router.replace({
        name: 'Task',
        params: {
          projectPrefix: currentTask.project.prefix,
          taskId: currentTask.id,
        },
      });

      if (task === selectedTask) {
        this.setActiveTaskId(null);
        this.$router.replace({name: 'Project', params: {projectId: currentTask.project.id}});
      } else {
        this.setActiveTaskId(currentTask.id)
      }
    },
    taskIsActive(task) {
      if (this.getActiveTask) {
        return this.getActiveTask.id === task.id;
      }
    },
    anyTaskIsActive() {
      return this.getActiveTask;
    },
  },
  computed: {
    ...mapGetters(
      {
        getTasks: 'tasks/getTasks',
        getActiveTask: 'tasks/getActiveTask',
        isFetching: 'tasks/isFetching',
        isFetched: 'tasks/isFetched',
        singleTaskIsNotFound: 'tasks/getSingleTaskIsNotFound',
        getActiveStatusFilter: 'tasksFilters/getFilterByStatus',
        getInitialSettings: 'user/getInitialSettings',
      },
    ),
  },
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>