<template>
  <div class="users-manage">
    <div class="flex aic jcsb mb1">
      <h4 @click="openModalTeam">{{ teamName }}</h4>
      <button @click="isModalVisible = true" class="btn btn--sm">Invite & manage</button>
    </div>
    <div class="users-manage__avatars flex aic">
      <template v-for="user in getUsers.slice(0, avatarMaxCnt)">
        <UserAvatar :user="user" :size="32" />
      </template>
      <div v-if="getUsers.length > avatarMaxCnt" class="users-manage__cnt flex aic jcc">
        +{{ getUsers.length - avatarMaxCnt }}
      </div>
    </div>
    <Modal :isVisible="isModalVisible" :key="'ModalInvitePeople'" :isClosable="true" @close="isModalVisible = false">
      <ModalInvitePeople/>
    </Modal>
    <Modal :isVisible="isModalTeamVisible" :key="'ModalTeam'" :isClosable="true" @close="isModalTeamVisible = false">
      <ModalTeam/>
    </Modal>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Modal from '../modal/Modal';
import ModalTeam from '@/components/modal-team/ModalTeam';
import ModalInvitePeople from '../modal-invite-people/ModalInvitePeople';
import UserAvatar from "@/components/user-avatar/UserAvatar";

export default {
  name: 'UsersManage',
  components: {
    Modal,
    ModalTeam,
    ModalInvitePeople,
    UserAvatar,
  },
  data() {
    return {
      avatarMaxCnt: 5,
      isModalVisible: false,
      isModalTeamVisible: false,
    }
  },
  methods: {
    openModalTeam() {
      if( this.userIsOwner ) {
        this.isModalTeamVisible = true;
      }
    },
  },
  computed: {
    ...mapGetters(
      {
        getTeams: 'teams/getTeams',
        getActiveTeam: 'teams/getActiveTeam',
        getInitialSettings: 'user/getInitialSettings',
        userIsOwner: 'user/userIsOwner',
      }
    ),
    teamName() {
      return this.getActiveTeam ? this.getActiveTeam.team_name : null;
    },
    getUsers() {
      let res = [];
      if (this.getTeams === null) {
        return res;
      }
      const initialSettings = this.getInitialSettings;
      const teamId = initialSettings.team.team_id;
      let team;
      for (let item of this.getTeams) {
        if (item.team_id + '' === teamId + '') {
          team = item;
          break;
        }
      }
      if (!team || !team.assigned_users) {
        return res;
      }

      let i = 0;
      for (let user of team.assigned_users) {
        if (i >= this.avatarMaxCnt) {
          break;
        }
        res.push(user);
      }
      return res;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>