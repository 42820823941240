// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/arrow-down.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comment-node[data-v-10bd8ce3]{background:var(--active-item-gray)}.comment-node__avatar[data-v-10bd8ce3]{width:32px;height:32px;border-radius:50%}.comment-node__child-node[data-v-10bd8ce3]{padding-left:10px;list-style:none}.comment-node__content-wrap[data-v-10bd8ce3]{margin-left:8px;text-align:justify;margin-bottom:10px}.comment-node__author-name[data-v-10bd8ce3]{font-family:var(--font-default);font-size:12px;line-height:18px;font-style:normal;font-weight:600}.comment-node__date[data-v-10bd8ce3]{color:var(--text-gray)}.comment-node__comment-content[data-v-10bd8ce3],.comment-node__date[data-v-10bd8ce3]{font-family:var(--font-default);font-size:12px;line-height:18px;font-style:normal;font-weight:400}.comment-node__comment-content[data-v-10bd8ce3]{color:var(--dark)}.comment-node__comment-content a[data-v-10bd8ce3]{text-decoration:none}.comment-node__comment-reply[data-v-10bd8ce3]{text-decoration:none;color:var(--gray)}.comment-node__author-role[data-v-10bd8ce3]{font-size:10px;line-height:15px;font-weight:600;background:var(--white);height:16px;width:40px}.comment-node__author-role[data-v-10bd8ce3],.comment-node__show-replies[data-v-10bd8ce3]{color:var(--vivid-blue);font-family:var(--font-default);font-style:normal}.comment-node__show-replies[data-v-10bd8ce3]{font-size:12px;line-height:18px;font-weight:500}.comment-node__show-replies[data-v-10bd8ce3]:before{content:\"\";display:block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");width:16px;height:16px;margin-right:4px}.comment-node__show-replies_close[data-v-10bd8ce3]:before{transform:rotate(180deg)}", ""]);
// Exports
module.exports = exports;
