<template>
  <div class="tasks-panel__container w100">
    <div class="tasks-panel__header-container flex jcsb w100">
      <div class="tasks-panel__header-tasks-status flex">
        <div @click="filterByStatus(1)" class="tasks-panel__status-item" :class="isActiveFilter(1) ? 'active' : ''">Open
          Tickets
        </div>
        <div @click="filterByStatus(2)" class="tasks-panel__status-item" :class="isActiveFilter(2) ? 'active' : ''">
          Backlog
        </div>
      </div>
      <div class="tasks-panel__header-tasks-status flex">
        <div @click="filterByStatus(3)" class="tasks-panel__status-item" :class="isActiveFilter(3) ? 'active' : ''">
          Automation
        </div>
        <div @click="filterByStatus(4)" class="tasks-panel__status-item" :class="isActiveFilter(4) ? 'active' : ''">
          How to
        </div>
      </div>
    </div>
    <div class="tasks-panel__box flex fdc">
      <ChecksList/>
      <TasksList/>
    </div>
    <Instruction/>
  </div>
  <transition name="slide-fade">
    <TasksRightSidebar v-if="getActiveTask"/>
  </transition>
</template>

<script>
import Instruction from '../instruction/Instruction';
import ChecksList from '../checks-list/ChecksList';
import TasksList from '../tasks-list/TasksList';
import TasksRightSidebar from '../tasks-right-sidebar/TasksRightSidebar';
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'TasksPanel',
  components: {
    TasksRightSidebar,
    TasksList,
    ChecksList,
    Instruction,
  },
  methods: {
    ...mapMutations(
      {
        setFilterByStatus: 'tasksFilters/setFilterByStatus',
        setSingleTaskIsNotFound: 'tasks/setSingleTaskIsNotFound',
        setTasks: 'tasks/setTasks',
      }
    ),
    filterByStatus(status) {
      if(!this.isActiveFilter(status)) {
        this.setSingleTaskIsNotFound(false);
        this.setTasks([]);
        this.setFilterByStatus(status);
      }
    },
    isActiveFilter(status) {
      return this.getActiveStatusFilter === status;
    },
  },
  computed: {
    ...mapGetters(
      {
        getActiveTask: 'tasks/getActiveTask',
        getActiveProject: 'projects/getActiveProject',
        getActiveStatusFilter: 'tasksFilters/getFilterByStatus'
      },
    ),
    projectName: function () {
      return this.getActiveProject ? this.getActiveProject.project_name : 'All Projects';
    }
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>