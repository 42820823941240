// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/outer-border-bottom.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/outer-border-top.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".project-item{padding:8px 12px 8px 8px;background-color:var(--mirage);position:relative;border-radius:8px 0 0 8px;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none}.project-item:not(:last-child){margin-bottom:5px}.project-item__main-content-wrap{display:flex}.project-item--active{background-color:var(--ebony-clay)}.project-item--active:after,.project-item--active:before{content:\"\";background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");display:block;width:9px;height:6px;position:absolute;right:0;bottom:-6px}.project-item--active:before{top:-6px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");opacity:1}.project-item--active .project-item__tasks-count{background:var(--white);color:var(--black)}.project-item__toggler{margin-right:8px;width:32px}.project-item__toggler .toggler__input:checked{background:var(--dark)}.project-item__icon{width:32px;height:32px;margin-right:8px}.project-item__symbol{border-radius:8px;font-family:var(--font-default);font-size:12px;line-height:18px;font-style:normal;font-weight:600;text-align:center;color:#fff}.project-item__label-text{font-size:14px;line-height:21px;font-weight:600;white-space:nowrap;width:140px;text-overflow:ellipsis}.project-item__label-text,.project-item__tasks-count{font-family:var(--font-default);font-style:normal;overflow:hidden;color:var(--white)}.project-item__tasks-count{cursor:pointer;border-radius:45px;padding:6px;background:var(--shuttle-gray);width:29px;height:29px;font-size:8px;line-height:29px;font-weight:500}", ""]);
// Exports
module.exports = exports;
