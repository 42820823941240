<template>
  <div @click="toggleMenu" class="sidebar__person person db w100 mb0 p0">
    <div class="person__container flex jcfs aic w100 m0">
      <div class="person__avatar db">
        <UserAvatar :size="32" />
      </div>
      <div class="person__info flex fww jcfs aic">
        <span class="person__name ib w100 m0 p0">{{ getFullName ?? 'Hello, username' }}</span>
<!--        <span class="person__version ib w100 m0 p0">Program version: 1.4</span>-->
      </div>
      <div class="person__popup flex jcc aic">
        <button class="person__popup-btn db w100 h100 m0 p0 pointer"></button>
      </div>
      <div class="person__additional-info"></div>
    </div>
    <div id="person-menu" class="person__menu" v-if="menuIsVisible">
      <div @click="openProfile" class="person__menu-item">
        Profile
      </div>
      <div @click="logout" class="person__menu-item person__menu-item--logout">
        Logout
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import UserAvatar from "@/components/user-avatar/UserAvatar";

export default {
  name: 'Person',
  components: {
    UserAvatar,
  },
  created() {
    window.addEventListener('click', (event) => {
      const element = event.target;
      if (!element.closest('.sidebar__person.person .person-menu') && (!element.closest('.sidebar__person.person'))) {
        this.menuIsVisible = false;
      }
    })
  },
  data() {
    return {
      menuIsVisible: false,
    };
  },
  methods: {
    ...mapMutations(
      {
        setJWT: 'common/setJWT',
        setInitialSettings: 'user/setInitialSettings',
      }
    ),
    toggleMenu() {
      this.menuIsVisible = !this.menuIsVisible;
    },
    openProfile() {
      this.modalProfile.active = true;
      this.modalProfile.closable = true;
    },
    logout() {
      this.setJWT('');
      this.$router.push('/');
    }
  },
  computed: {
    ...mapGetters(
      {
        getInitialSettings: 'user/getInitialSettings',
        modalProfile: 'common/getModalProfile'
      }
    ),
    getFullName() {
      let user = this.getInitialSettings ? this.getInitialSettings.user : null;
      if ( user && ( user.first_name || user.last_name ) ) {
        return `${user.first_name} ${user.last_name}`;
      }
      return null;
    },
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>