<template>
  <li class="comment-node">
    <div class="comment-node__comment-wrap flex jcsb">
      <div class="comment-node__avatar"
           :style="`background: url('${commentNode.comment_user.avatar}') no-repeat center/32px`"></div>
      <div class="comment-node__content-wrap flex fdc w100">
        <div class="comment-node__content-header flex jcsb aic mb1 w100">
          <div class="comment-node__content-left-block flex aic">
            <div class="comment-node__author-name">
              {{ userName }}
            </div>
            <div class="tag comment-node__author-role flex aic ml1">
              {{ commentNode.user_role.role_label }}
            </div>
          </div>
          <div class="comment-node__content-right-block">
            <div class="comment-node__date">
              {{ date }}
            </div>
          </div>
        </div>
        <div class="comment-node__comment-content">
          <read-more class="mb1" more-str="Read more" :text="commentNode.comment_content" link="#" less-str="Hide"
                     :max-chars="403"/>
          <UploadedFiles :uploadedFiles="commentNode.attached_files"/>
          <div>
            <a @click.prevent="toggleReplyForm" class="comment-node__comment-reply" href="#">Reply</a>
          </div>
          <div class="mt1" v-if="canShowReplyForm">
          <AddComment
              :showAvatar="false" :taskId="commentNode.task_id"
              :replyTo="commentNode.comment_id"></AddComment>
            </div>
          <div v-if="childrenCommentsCount > 0" class="comment-node__show-replies-wrap mt1">
            <a class="comment-node__show-replies flex aic" :class="[showChildren ? 'comment-node__show-replies_close' : '']"
               @click.prevent="toggleChildren"
               href="#">{{ showRepliesMessage }}</a>
          </div>
        </div>
      </div>
    </div>
    <ul class="comment-node__child-node"
        v-if="commentNode.comment_children && commentNode.comment_children.length && showChildren">
      <CommentNode v-for="child in commentNode.comment_children" :commentNode="child"/>
    </ul>
  </li>
</template>

<script>

import date from "locutus/php/datetime/date";
import strtotime from "locutus/php/datetime/strtotime";
import UploadedFiles from "../uploaded-files/UploadedFiles";
import AddComment from "../add-comment/AddComment";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'CommentNode',
  components: {UploadedFiles, AddComment},
  props: {
    commentNode: Object,
  },
  data: function () {
    return {
      showChildren: false,
      replyFormId: `reply_form_${(+new Date).toString(16)}`,
      ...mapGetters(
          {
            getActiveReplyForm: "tasksRightSidebar/getActiveReplyForm",
          }
      ),
    }
  },
  methods: {
    toggleChildren() {
      this.showChildren = !this.showChildren;
    },
    toggleReplyForm() {
      if (this.getActiveReplyForm() === this.replyFormId) {
        this.setActiveReplyForm(null);
      } else {
        this.setActiveReplyForm(this.replyFormId);
      }
    },
    ...mapMutations(
        {
          setActiveReplyForm: "tasksRightSidebar/setActiveReplyForm",
        }
    ),
  },
  computed: {
    childrenCommentsCount() {
      if (this.commentNode.comment_children && this.commentNode.comment_children.length) {
        return this.commentNode.comment_children.length;
      }
    },
    showRepliesMessage() {
      return `${this.showChildren ? "Hide" : "Show"} ${this.childrenCommentsCount} ${this.childrenCommentsCount < 2 ? "reply" : "replies"}`;
    },
    date() {
      return date("M d.Y | g:i A", strtotime(this.commentNode.comment_date));
    },
    userName() {
      let firstName = this.commentNode.comment_user.first_name.trim();
      let lastName = this.commentNode.comment_user.last_name.trim();

      firstName = firstName.length ? firstName : 'Guest';
      lastName = lastName.length ? lastName : 'Guest';

      return `${firstName} ${lastName}`;
    },
    canShowReplyForm() {
      return this.replyFormId === this.getActiveReplyForm();
    }
  }
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>

<style lang="postcss">
#readmore {
  text-decoration: none;
  color: var(--gray);
}
</style>