<template>
  <div v-show="projectID" class="instruction flex fdc">
    <div class="instruction__box flex fww">
      <h3 class="instruction__title w100">Instruction</h3>
      <div class="instruction__desc w100 mb2">Instructions on how to properly install the widget on your site.</div>
      <div class="instruction__actions flex fdc">
        <div class="instruction__item">
          <div class="instruction__subtitle">Install code snippet into &lt;head&gt;</div>
          <pre class="instruction__code">{{ widgetCode() }}</pre>
          <button class="instruction__btn btn" @click.prevent="copyToClipboard($event, widgetCode(), 'Copied!')">
            Copy <i class="icon icon--right icon--copy"></i>
          </button>
        </div>
        <div class="instruction__item flex aic">
          <div class="instruction__subtitle">PIN code to submit tickets:</div>
          <pre class="instruction__code mra ml1">{{ pinCode }}</pre>
          <button class="instruction__btn btn" @click.prevent="copyToClipboard($event, pinCode, 'Copied!')">
            Copy <i class="icon icon--right icon--copy"></i>
          </button>
        </div>
        <div class="instruction__item flex aic">
          <div class="instruction__subtitle instruction__subtitle--offset mra flex aic">
            <i class="icon icon--wp icon--left"></i>Also, we got WordPress plugin
          </div>
          <a class="instruction__btn btn" :href="downloadLink" download>Download</a>
        </div>
        <div class="instruction__item flex aic">
          <div class="instruction__subtitle">Project ID:</div>
          <pre class="instruction__code mra ml1">{{ projectID }}</pre>
          <button class="instruction__btn btn" @click.prevent="copyToClipboard($event, projectID, 'Copied!')">
            Copy <i class="icon icon--right icon--copy"></i>
          </button>
        </div>
      </div>
      <div class="instruction__img ml4">
        <div class="start__col--side">
          <div class="start__video" v-on:click="runVideo( $event, 'https://www.youtube.com/embed/J---aiyznGQ' )">
            <img class="start__img" src="../../assets/images/start-img.png" alt=""/>
            <span class="start__watch"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="instruction__box pv0">
      <div class="instruction__gradient flex fww">
        <div class="instruction__actions flex fdc">
          <h3 class="instruction__title w100">
            Some <span class="start__title--colored">killer features</span><br>include
          </h3>
          <div class="instruction__item instruction__item--bordered">
            <div class="flex aic">
              <div class="instruction__subtitle mra">Pixel perfect</div>
              <a class="instruction__btn" :href="pixelPerfectLink" target="_blank">Learn more</a>
            </div>
            <p>// build in widget</p>
          </div>
          <div class="instruction__item instruction__item--bordered">
            <div class="flex aic">
              <div class="instruction__subtitle mra">Form autofill</div>
              <a class="instruction__btn" :href="autofillLink" target="_blank">Learn more</a>
            </div>
            <p>// build in widget</p>
          </div>
        </div>
        <div class="instruction__img instruction__img--bordered ml4 flex fdc">
          <div class="instruction__item">
            <h2>Connected environments</h2>
            <p>It happens often when in order to test something specific, you need to redo the same issue on the environment you are testing in.</p>
            <p>This feature will allow the user to copy data from other environments that are connected to the same project.</p>
            <p><a class="instruction__btn" :href="connectionLink" target="_blank">Learn more</a></p>
            <p>// requires WordPress plugin</p>
          </div>
          <div class="instruction__item flex aic">
            <div class="instruction__subtitle">Secret key:</div>
            <pre class="instruction__code mra ml1">{{ secretKey }}</pre>
            <button class="instruction__btn btn" @click.prevent="copyToClipboard($event, secretKey, 'Copied!')">
              Copy <i class="icon icon--right icon--copy"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import config from 'config';
import {commonHelper} from '@/_helpers/common-helper';

export default {
  name: 'Instruction',
  data: function () {
    return {
      downloadLink: '/plugins/qat-wp-plugin.zip',
      pixelPerfectLink: 'https://docs.google.com/document/d/1yisuHpIWGLWVLOD7iYnOfdDic_LAU_Xxux-dty1I0vk',
      autofillLink: 'https://docs.google.com/document/d/1V57ng552htzluEFymygtoteKEIKzk9IR4fyA8WyDi-g',
      connectionLink: 'https://docs.google.com/document/d/1weHSu76_NfoDYBcFHFaHPxyw--0ZRdr-AiYPaj4bnCI',
      widgetCode: () => `<script src='${this.errorsProvider}'><\/script>
<script>
(function (w, d, s, o, f) {
  w['JS-Widget'] = o;
  w[o] = w[o] || function () {
    (w[o].q = w[o].q || []).push(arguments)
  };
  let js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
  js.id = o;
  js.src = f;
  js.async = true;
  fjs.parentNode.insertBefore(js, fjs);
}(window, document, 'script', 'mw', '${this.bootstrapUrl}'));
mw(
  'init',
  {
    project_id: '${this.projectID}',
    app_url: '${this.appUrl}',
    api_url: '${this.apiUrl}',
  }
);
<\/script>`,
    }
  },
  computed: {
    ...mapGetters(
      {
        getActiveProject: 'projects/getActiveProject',
      },
    ),
    projectID() {
      return this.getActiveProject ? this.getActiveProject.project_id : null;
    },
    pinCode() {
      return this.getActiveProject ? this.getActiveProject.pincode : null;
    },
    secretKey() {
      return this.getActiveProject ? this.getActiveProject.secret_key : null;
    },
    apiUrl() {
      return config.apiUrl;
    },
    appUrl() {
      const url = new URL(window.location.href);
      return `${url.protocol}//${url.host}/widget/app.js`;
    },
    bootstrapUrl() {
      const url = new URL(window.location.href);
      return `${url.protocol}//${url.host}/widget/bootstrap.js`;
    },
    errorsProvider() {
      const url = new URL(window.location.href);
      return `${url.protocol}//${url.host}/widget/errorsProvider.js`;
    },
  },
  methods: {
    copyToClipboard(e, copy_data, replacement) {
      return commonHelper.copyToClipboard(e, copy_data, replacement);
    },
    runVideo(e, video) {
      document.querySelector('.start__col--side').innerHTML = '<iframe width="100%" height="360" src="' + video + '?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>';
    },
  },
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>