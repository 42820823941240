// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/attach-files.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/send-comment.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".add-comment__files-upload[data-v-9fd93d0a]{display:block;width:16px;height:16px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;background-size:contain;cursor:pointer}.add-comment__files-upload input[type=file][data-v-9fd93d0a]{display:none}.add-comment__send-comment[data-v-9fd93d0a]{width:24px;height:24px;padding:0;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%;background-size:contain;margin:0 0 0 8px}.add-comment__input-wrap[data-v-9fd93d0a]{flex-grow:2;margin-left:16px}.add-comment__input-wrap_no-margin[data-v-9fd93d0a]{margin-left:0}.add-comment__controls[data-v-9fd93d0a]{margin-left:-68px;padding:0 10px;z-index:1}.add-comment__error[data-v-9fd93d0a]{color:red}.add-comment__attached-files[data-v-9fd93d0a],.add-comment__error[data-v-9fd93d0a]{font-family:var(--font-default);font-size:14px;line-height:21px;font-style:normal;font-weight:400}", ""]);
// Exports
module.exports = exports;
