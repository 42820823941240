// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/arrow-up.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".comments-tree__title[data-v-5e93c49c]{font-family:var(--font-default);font-size:12px;line-height:18px;font-style:normal;font-weight:600;text-transform:uppercase}.comments-tree__count[data-v-5e93c49c]{color:var(--text-gray)}.comments-tree__comments-list[data-v-5e93c49c]{list-style:none;padding:0;margin:0}.comments-tree__comments-list>.comment-node[data-v-5e93c49c]{padding:16px 16px 0}.comments-tree__comments-list>.comment-node[data-v-5e93c49c]:last-child{padding-bottom:16px}.comments-tree__comments-toggler[data-v-5e93c49c]{padding:0;width:24px;height:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");transform:rotate(180deg)}.comments-tree__comments-toggler_open[data-v-5e93c49c]{transform:rotate(0deg)}", ""]);
// Exports
module.exports = exports;
