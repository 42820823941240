// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/icon-close.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".modal{position:fixed;z-index:9999;top:0;bottom:0;left:0;right:0;background-color:rgba(0,0,0,.5);align-items:center;display:flex;justify-content:center}.modal__wrapper{overflow:hidden;overflow-y:auto;padding:40px 0;width:100%}.modal__box{position:relative;margin:0 auto;max-width:480px;overflow:hidden;padding:32px 4px;border-radius:32px;background-color:var(--white);color:var(--shuttle-gray);width:100%;max-height:calc(100vh - 80px)}.modal__close{background-size:contain;background:transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;border:0;cursor:pointer;width:24px;height:24px;position:absolute;right:22px;top:22px;transition:all .4s;z-index:99}.modal__close:hover{opacity:.5}", ""]);
// Exports
module.exports = exports;
