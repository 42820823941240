<template v-if="uploadedFiles">
  <div class="uploaded-files">
    <ul class="uploaded-files__list">
      <template v-for="{file} in uploadedFiles">
        <li class="uploaded-files__item flex jcsb aic mb1">
          <div class="uploaded-files__file file flex aic"
               :class="getExtensionClass(file.subtype)">
            <a target="_blank" class="uploaded-files__file-link"
               :href="file.url">{{ file.filename }}</a>
          </div>
          <a target="_blank" :href="file.url" class="uploaded-files__file-upload"></a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'UploadedFiles',
  props: {
    uploadedFiles: {
      type: Array,
      default: []
    }
  },
  computed: {
    getExtensionClass() {
      return type => {
        let class_ = '';
        switch (type) {
          case 'pdf':
            class_ = type
            break;
        }
        return `file_icon_${class_}`;
      }
    }
  }
}
</script>

<style lang="postcss">
@import "styles.css";
</style>