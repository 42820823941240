<template>
  <div class="qat flex jcsb aistr w100" :class="getNotifications.length ? 'qat--noticed' : ''">
    <slot/>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'LayoutDefault',

  computed: {
    ...mapGetters({
      getNotifications: 'notifications/getNotifications',
    }),
  },
}
</script>

<style lang="postcss">
@import "styles.css";
</style>