// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/arrow.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".person[data-v-862aa17e]{position:relative;padding:0 16px 16px}.person__container[data-v-862aa17e]{cursor:pointer;padding:16px;background:var(--charade);border-radius:8px}.person__avatar[data-v-862aa17e]{width:32px;height:32px;border-radius:50%;margin-right:16px;flex-shrink:0;background-position:50%;background-size:cover;background-repeat:no-repeat}.person__info[data-v-862aa17e]{width:calc(100% - 72px);flex-grow:1}.person__name[data-v-862aa17e]{font-size:14px;line-height:21px;font-weight:500;color:var(--white)}.person__name[data-v-862aa17e],.person__version[data-v-862aa17e]{font-family:var(--font-default);font-style:normal}.person__version[data-v-862aa17e]{font-size:10px;line-height:15px;font-weight:400;color:var(--shuttle-gray)}.person__popup[data-v-862aa17e]{width:24px;height:24px;flex-shrink:0}.person__popup-btn[data-v-862aa17e]{border:none;outline:none;box-shadow:none;background-color:transparent;padding:0;margin:0;cursor:pointer;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:8px 4px;background-repeat:no-repeat}.person__menu[data-v-862aa17e]{color:var(--black);border-radius:8px;top:-27px;right:-182px}.person__menu[data-v-862aa17e],.person__menu[data-v-862aa17e]:after{position:absolute;background:var(--white);z-index:1}.person__menu[data-v-862aa17e]:after{content:\"\";width:12px;height:12px;left:-6px;bottom:10px;transform:rotate(135deg)}.person__menu-item[data-v-862aa17e]{min-width:180px;padding:10px 16px;font-family:var(--font-default);font-size:14px;line-height:21px;font-style:normal;font-weight:400;cursor:pointer;position:relative;display:flex;z-index:2}.person__menu-item[data-v-862aa17e]:hover{color:var(--blue)}.person__menu-item--logout[data-v-862aa17e]:hover{color:var(--red)}.person__menu-item[data-v-862aa17e]:after{content:\"\";position:absolute;bottom:0;width:80%;height:2px;background:var(--almost-white-gray)}", ""]);
// Exports
module.exports = exports;
