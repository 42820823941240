<template>
  <transition name="modal-fade">
    <div class="modal" v-if="isVisible">
      <div class="modal__wrapper" @click.self="isClosable ? closeModal() : null">
        <div class="modal__box" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
          <button type="button" class="modal__close" @click="closeModal" aria-label="Close modal"
                  v-show="isClosable"></button>
          <section class="modal__body" id="modalDescription">
            <slot/>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

export default {
  name: 'Modal',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    isClosable: {
      type: Boolean,
      required: false,
    },
  },
  methods: {
    closeModal() {
      document.body.style.overflow = '';
      this.$emit('close');
    },
  },
  watch: {
    isVisible(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
      }
    },
  },
};
</script>

<style lang="postcss">
@import "styles.css";
</style>