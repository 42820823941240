<template>
  <div class="comments-tree">
    <div v-if="getComments && getComments.comments" class="comments-tree__header flex jcsb aic mb1">
      <div class="comments-tree__title">Comments: <span class="comments__count">{{
          getComments.total_comments
        }}</span>
      </div>
      <button @click="toggleComments" class="button comments-tree__comments-toggler"
              :class="[showComments ? 'comments-tree__comments-toggler_open' : '']"></button>
    </div>
    <ul v-if="showComments" class="comments-tree__comments-list">
      <CommentNode v-for="comment in getComments.comments" :commentNode="comment"/>
    </ul>
  </div>
</template>

<script>
import CommentNode from '../comment-node/CommentNode';
import {mapGetters} from 'vuex';

export default {
  name: 'CommentTree',
  components: {CommentNode},
  data: () => {
    return {
      showComments: false,
    }
  },
  methods: {
    toggleComments() {
      this.showComments = !this.showComments;
    },
  },
  computed: {
    ...mapGetters(
        {
          getActiveTask: 'tasks/getActiveTask',
          getComments: 'comments/getComments',
        }
    )
  }
}

</script>

<style scoped lang="postcss">
@import "styles.css";
</style>