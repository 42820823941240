<template>
  <div class="checks-list flex fdc">
    <div class="checks-list__container w100 flex aic" :class="isActiveFilter ? 'active' : ''">
      <iframe id="google"
          src="https://docs.google.com/document/d/e/2PACX-1vRrMDwKxZZBOPNJt3M74OImfyta6IcOvKI_Khj40iFPZ80HISxJQSIp_fXe2FaZCQlwwX65MG3_o_37/pub?embedded=true"></iframe>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ChecksList',
  computed: {
    ...mapGetters(
      {
        getActiveStatusFilter: 'tasksFilters/getFilterByStatus',
      }
    ),
    isActiveFilter() {
      return this.getActiveStatusFilter === 4;
    },
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>