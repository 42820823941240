<template>
  <div class="projects" :class="processed ? 'projects--load' : ''">
    <div class="projects__team" v-for="(team, i) in teams">
      <h4>{{ team.name }}</h4>
      <div class="projects__item flex aic jcsb"
           v-for="(space, k) in team.spaces"
           v-show="space.remote_id"
           :class="space.linked ? 'is-used' : ''">
        <h5>{{ space.project_name }}</h5>
        <button @click.prevent="linkUnlinkProject($event,k,space,i)">
          {{ space.linked ? 'unlink me' : 'use me' }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "@/_services/api.service";
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
  name: 'ProjectsRemote',
  data() {
    return {
      teams: [],
      processed: true,
    }
  },
  mounted() {
    if ( this.teams.length > 0 ) {
      return this.teams;
    }

    let initialSettings = this.getInitialSettings,
        query = {
          team_id: initialSettings.team.team_id
        };
    apiService.get( 'team-integrations/get_spaces', query )
      .then( response => {
        this.teams = response.data;
        return response.data;
      } )
      .catch( error => {
        this.$store.dispatch( 'teamsIntegration/fetchIntegration', this.getActiveTeam )
          .then( response => {
            if ( response.data.integration_id > 0 ) {
              this.fetchTeams();
            }
          } );
      } )
      .finally( () => {
        this.processed = false;
      } );
  },
  methods: {
    ...mapActions(
      {
        fetchProjects: 'projects/fetchProjects',
        fetchTeams: 'teams/fetchTeams',
      }
    ),
    linkUnlinkProject(event, project_index, project, team_index) {
      let btn = event.target;
      btn.classList.add('is-load');

      let initialSettings = this.getInitialSettings;
      let formData = new FormData;
          formData.append('project_name', project.project_name);
          formData.append('project_id', project.project_id);
          formData.append('domain', '');
          formData.append('team_id', initialSettings.team.team_id + '');
          formData.append('remote_id', project.linked ? '' : project.remote_id);
          formData.append('project_prefix', project.remote_custom_id);

      let type = project.linked ? 'update' : 'create';
      apiService.post('/projects/' + type, formData)
        .then( response => {
          if ( response.data.project_id ) {
            (async () => {
              await this.fetchProjects();
            })();
            this.teams[team_index].spaces[project_index].project_id = response.data.project_id;
            this.teams[team_index].spaces[project_index].linked = !project.linked;
          }
          return response.data;
        } )
        .catch( error => {
          this.response = error.response.data;
        } )
        .finally( () => {
          btn.classList.remove('is-load');
        } );
    }
  },
  computed: {
    ...mapGetters(
      {
        getActiveTeam: 'teams/getActiveTeam',
        getInitialSettings: 'user/getInitialSettings',
      }
    ),
  },
}
</script>

<style lang="postcss">
@import "styles.css";
</style>
