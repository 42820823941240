<template>
  <div class="tasks-right-sidebar h100vh flex" v-click-outside="onClickOutside">
    <div class="tasks-right-sidebar-task">
      <div class="tasks-right-sidebar-task__header flex fww jcsb">
        <div class="tasks-right-sidebar-task__header-left flex aic">
          <div class="tasks-right-sidebar-task__task-name">{{ task.project.prefix }}-{{ task.id }}</div>
        </div>
        <div class="tasks-right-sidebar-task__header-right flex aic">
          <template v-if="projectLinked && clickupIsConnected">
            <button v-if="!task.remote_task_id && sendWithComment" class="btn btn--dark btn--sm" :class="sendTaskIsLoad ? 'is-load' : ''" @click.prevent="sendTaskWithComment">
              Send task <i class="icon icon--clickup"></i>
            </button>
            <button v-else-if="!task.remote_task_id && !sendWithComment" class="btn btn--dark btn--sm" :class="sendTaskIsLoad ? 'is-load' : ''" @click.prevent="sendTask">
              Send task <i class="icon icon--clickup"></i>
            </button>
            <button v-else class="btn btn--dark btn--sm" @click.prevent="openTask">
              View task <i class="icon icon--clickup"></i>
            </button>
          </template>

          <button v-if="task.status === 1 + ''" @click="changeStatus(2)" class="btn btn--muted btn--sm">
            Save to backlog
          </button>
          <button v-if="task.status === 2 + ''" @click="changeStatus(1)" class="btn btn--muted btn--sm">
            Save to open
          </button>

          <button @click="deleteTask"
                  class="button-with-icon tasks-right-sidebar-task__delete-task flex jcc aic"></button>
          <button @click="closeTask" class="button tasks-right-sidebar-task__close-task"></button>
        </div>

        <div v-if="!clickupIsConnected" class="flex w100 aic fww jcsb">
          <h5>You haven't connected ClickUp yet.</h5>
          <button
              class="button add-project-modal__btn-default add-project-modal__btn-bot add-project-modal__btn-clickup flex jcc aic"
              @click.prevent="clickupAuthorise">
            Allow clickup
          </button>
        </div>
        <div v-if="!projectLinked && clickupIsConnected" class="flex w100 aic fww jcsb">
          <h5>This project is not connected to ClickUp project yet. Please select one below:</h5>
          <ProjectsConnect @updateStatus="onUpdateStatus"/>
        </div>
      </div>
      <hr class="hr tasks-right-sidebar-task__hr"/>
      <div class="tasks-right-sidebar-task__links mb2">
        <div class="tasks-right-sidebar-task__domain mb1 flex aic">
          <a @mouseover="goToSiteTooltip = true" @mouseleave="goToSiteTooltip = false"
             :href="getTaskDomainLink">{{ getTaskDomain }}</a>
          <Tooltip v-visible="goToSiteTooltip" class="ml1" :tooltipType="'left'">Go to the site</Tooltip>
        </div>
        <div @click="copyToClipboard($event,getTaskPath,null)" @mouseover="copyLinkMouseOver" @mouseleave="copyTaskLinkTooltip = false"
             class="tasks-right-sidebar-task__link-wrap relative">
          <div class="tasks-right-sidebar-task__link">{{ getTaskPath }}</div>
          <Tooltip v-if="copyTaskLinkTooltip" class="absolute tasks-right-sidebar-task__link-tooltip"
                   :tooltipType="'left'">
            <span :class="`tasks-right-sidebar-task__link-tooltip-text_${clickToCopyTextColor}`">{{
                clickToCopyText
              }}</span>
          </Tooltip>
        </div>
      </div>
      <div class="tasks-right-sidebar-task__info flex">
        <div class="info-block flex">
          <div class="icon info-block__assigned-to-icon"></div>
          <div class="info-block__assigned-to-info">
            <div class="info-block__assigned-to-label">Created by:</div>
            <div class="info-block__assigned-to-user">
              <div class="tag info-block__assigned-to-user-role">QA</div>
              <div class="info-block__assigned-to-user-name">#</div>
            </div>
          </div>
        </div>
        <div class="info-block flex">
          <div class="icon info-block__created-at-icon"></div>
          <div class="info-block__created-at-info">
            <div class="info-block__created-at-label">Date:</div>
            <div class="info-block__created-at-date">{{ date }}</div>
          </div>
        </div>
      </div>
      <div class="tasks-right-sidebar-task__user-info flex fww mt2">
        <template v-for="userInfoItem in userInfo">
          <UserInfoItem :userInfoItem="userInfoItem"/>
        </template>
      </div>
      <div class="tasks-right-sidebar-task__errors-container flex fww jcc mt1">
        <ErrorsContainer :images="imagesUrl" :consoleData="consoleData"/>
      </div>
      <div class="tasks-right-sidebar-task__description mt2">
        <TaskDescription :description="description" :uploadedFiles="uploadedFiles"/>
      </div>
<!--      <hr class="hr tasks-right-sidebar-task__hr">-->
<!--      <div class="tasks-right-sidebar-task__add-comment mt2">-->
<!--        <AddComment :taskId="getActiveTask.id"/>-->
<!--      </div>-->
<!--      <hr class="hr tasks-right-sidebar-task__hr">-->
<!--      <div class="tasks-right-sidebar-task__comments">-->
<!--        <CommentsTree ref="commentsTree"/>-->
<!--      </div>-->
<!--      <hr class="hr tasks-right-sidebar-task__hr">-->
    </div>
    <Modal v-if="sendWithComment" :isVisible="showCommentModal" :isClosable="true" @close="onCloseCommentModal">
      <div class="md-editor">
        <quill-editor style="height: 200px" :options="editorOption" v-model:value="commentModalContent"/>
        <div class="md-editor__comment-save">
          <button @click="sendTask">Save</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import {commonHelper} from '@/_helpers/common-helper';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import apiService from '@/_services/api.service';
import ErrorsContainer from '@/components/errors-container/ErrorsContainer';
import TaskDescription from '@/components/task-description/TaskDescription';
import ProjectsConnect from '@/components/projects-connect/ProjectsConnect';
import AddComment from '@/components/add-comment/AddComment';
import UserInfoItem from '@/components/user-info-item/UserInfoItem';
import ModalProjects from '@/components/modal-projects/ModalProjects';
import CommentsTree from '../comments-tree/CommentsTree';
import strtotime from 'locutus/php/datetime/strtotime';
import date from 'locutus/php/datetime/date';
import Tooltip from '../tooltip/Tooltip';
import Modal from "../modal/Modal";
import {quillEditor, Quill} from 'vue3-quill';
import vClickOutside from 'click-outside-vue3';

export default {
  name: 'TasksRightSidebar',
  directives: {
    visible: function (el, binding) {
      el.style.visibility = !!binding.value ? 'visible' : 'hidden';
    },
    clickOutside: vClickOutside.directive,
  },
  mounted() {
    this.projectLinked = this.getActiveProject ? this.getActiveProject.remote_id : false
  },
  data() {
    return {
      goToSiteTooltip: false,
      copyTaskLinkTooltip: false,
      clickToCopyText: 'Click to copy',
      active: false,
      projectLinked: false,
      commentModalContent: '',
      showCommentModal: false,
      sendTaskIsLoad: false,
      sendWithComment: false,
      editorOption: {
        modules: {
          toolbar: [
            [{header: [1, 2, 3, 4, 5, 6, false]}],
            ['bold'],
            ['blockquote'],
            [{list: 'ordered'}, {list: 'bullet'}],
          ]
        }
      },
    }
  },
  components: {
    Modal,
    Tooltip,
    CommentsTree,
    AddComment,
    ErrorsContainer,
    TaskDescription,
    UserInfoItem,
    ModalProjects,
    ProjectsConnect,
    quillEditor
  },
  methods: {
    ...mapActions(
      {
        actionDeleteTask: 'tasks/deleteTask',
        actionChangeStatus: 'tasks/changeStatus',
        fetchTeams: 'teams/fetchTeams',
      }
    ),
    ...mapMutations(
      {
        setActiveTaskId: 'tasks/setActiveTaskId',
      }
    ),
    onClickOutside( event ) {
      let el = event.target;
      if( ! el.classList.contains( 'task-list__item-wrap' ) && ! el.closest( '.task-list__item-wrap' ) ) {
        this.closeTask();
      }
    },
    onUpdateStatus(data) {
      this.projectLinked = data;
    },

    clickupAuthorise() {
      let clickup = this.getActiveTeam ? this.getActiveTeam.integrations.clickup : null;
      if (clickup) {
        window.location.href = `${clickup.api_url}?client_id=${clickup.client_id}&redirect_uri=${window.location.href}`;
      }
    },

    closeTask() {
      this.setActiveTaskId(null);
      this.$router.replace({name: 'Project', params: {projectPrefix: this.$route.params.projectPrefix}});
    },

    copyToClipboard(e, copy_data, replacement) {
      this.clickToCopyText = "Copied";
      return commonHelper.copyToClipboard(e, copy_data, replacement);
    },

    copyLinkMouseOver() {
      this.clickToCopyText = "Click to copy";
      this.copyTaskLinkTooltip = true
    },

    changeStatus(status) {
      this.actionChangeStatus({task: this.task, status: status});
    },

    openTask() {
      if(this.getActiveTask.remote_task_id) {
        window.open( 'https://app.clickup.com/t/' + this.getActiveTask.remote_task_id, '_blank' ).focus();
      }
    },

    deleteTask() {
      if (confirm('Delete task?')) {
        this.actionDeleteTask(this.task);
      }
    },

    sendTaskWithComment() {
      this.showCommentModal = true;
    },

    cancelCommentTask() {
      this.showCommentModal = false;
    },

    sendTask() {
      this.sendTaskIsLoad = true;
      this.showCommentModal = false;
      const formData = new FormData();
      formData.append('task_id', this.task.id);
      formData.append('app_id', '1');
      if (this.commentModalContent.length) {
        formData.append('task_comment', this.commentModalContent);
      }
      apiService.post('/tasks/send', formData)
        .then(response => {
          if (response.data.id) {
            this.task.remote_task_id = response.data.id;
          }
          return response.data;
        })
        .catch(error => {
          this.$store.dispatch( 'teamsIntegration/fetchIntegration', this.getActiveTeam )
            .then( response => {
              if ( response.data.integration_id > 0 ) {
                this.fetchTeams();
              }
            } );
        })
        .finally(() => {
          this.sendTaskIsLoad = false;
        });
    },

    onCloseCommentModal() {
      this.showCommentModal = false;
      this.commentModalContent = '';
      this.sendTask();
    }
  },
  computed: {
    ...mapGetters(
      {
        getTasks: 'tasks/getTasks',
        getActiveTask: 'tasks/getActiveTask',
        getActiveTeam: 'teams/getActiveTeam',
        getActiveProject: 'projects/getActiveProject',
      }
    ),
    imagesUrl() {
      return [
        this.task.screenshot ? this.task.screenshot.url : ''
      ];
    },
    userInfo() {
      return commonHelper.parseUserInfo(this.task);
    },
    getTaskPath() {
      if (commonHelper.isValidHttpUrl(this.task.browser_info.link)) {
        return commonHelper.getPath(this.task.browser_info.link);
      }
      return '';
    },
    getTaskDomain() {
      if (commonHelper.isValidHttpUrl(this.task.browser_info.link)) {
        return commonHelper.getDomain(this.task.browser_info.link);
      }
      return '';
    },
    getTaskDomainLink() {
      if (commonHelper.isValidHttpUrl(this.task.browser_info.link)) {
        return commonHelper.getDomainLink(this.task.browser_info.link);
      }
      return '';
    },
    getLink() {
      if (commonHelper.isValidHttpUrl(this.task.browser_info.link)) {
        return this.task.browser_info.link;
      }
      return '';
    },
    date() {
      return date("M d.Y | g:i A", strtotime(this.task.created_at));
    },
    consoleData() {
      return this.task.console;
    },
    description() {
      return this.task.content;
    },
    uploadedFiles() {
      return this.task.attached_files;
    },
    clickToCopyTextColor() {
      return this.clickToCopyText === 'Copied' ? 'green' : '';
    },
    task() {
      return this.getActiveTask;
    },
    clickupIsConnected() {
      const team = this.getActiveTeam;
      return team && team.hasOwnProperty('integrations') && team.integrations.hasOwnProperty('clickup') && team.integrations.clickup.is_authorised;
    },
  },
}
</script>
<style scoped lang="postcss">
@import "styles.css";
</style>