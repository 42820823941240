<template>
  <form class="grid" enctype="multipart/form-data" action="" method="post" @submit.prevent="saveTeam">
    <div class="grid__main">
      <div class="grid__col">
        <h3 class="grid__title">Your team</h3>
      </div>
      <div class="grid__col">
        <div class="grid__input--title">Main Information:</div>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="team-name">Name</label>
        <input class="grid__input" name="team_name" type="text" id="team-name"
               placeholder="Team Name" required minlength="3" maxlength="12"
               v-model="teamName" @input="validateTeamName">
        <p class="grid__error">{{ errorMessage }}</p>
      </div>
    </div>
    <div class="grid__footer flex jcfs">
      <button class="button mla" type="submit" :class="submitted ? 'is-load' : ''" :disabled="submitted">Save & Close</button>
    </div>
  </form>
</template>

<script>
import apiService from '@/_services/api.service';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Modal from '@/components/modal/Modal';

export default {
  name: 'ModalTeam',
  components: {
    Modal,
  },
  created() {
    localStorage.setItem('firstEntry', 'false');
    this.teamName = this.getActiveTeam ? this.getActiveTeam.team_name : null;
  },
  data() {
    return {
      errorMessage: '',
      submitted: false,
      teamName: null,
      blackList: [
        'rgbc',
        'rgbcode',
        'rgbcodecom',
        'rgbccom',
      ],
    }
  },
  methods: {
    ...mapActions(
      {
        fetchTeams: 'teams/fetchTeams',
      }
    ),
    validateTeamName(e) {
      if( /[^A-Za-z0-9]/.test( this.teamName ) ) {
        this.teamName = this.teamName.slice(0, -1);
      }
      this.errorMessage = '';
    },
    isValidTeamName(value) {
      let name = value.match(/[a-zA-Z0-9][a-zA-Z0-9-]{1,21}[a-zA-Z0-9]+/);
      // @see check validation subdomain https://stackoverflow.com/questions/26093545/how-to-validate-domain-name-using-regex
      return !!(name && name[0] && value === name[0]);
    },
    saveTeam(e) {
      let team = this.getInitialSettings ? this.getInitialSettings.team : null;
      if( team && this.isValidTeamName( this.teamName ) && this.blackList.indexOf( this.teamName ) === -1 ) {
        this.submitted = true;
        let data = new FormData( e.target );
            data.append('team_id', team.team_id);
        this.$store.dispatch( 'teams/updateTeam', data )
          .then( response => {
            this.fetchTeams();
            this.$parent.$emit('close');
            return response.data;
          } )
          .catch( error => {
            if(error.response.data.invalid_name) {
              this.errorMessage = error.response.data.invalid_name;
            }
          } )
          .finally( () => {
            this.submitted = false;
          } );
      } else {
        // TODO add notices:
        // not valid team name, and team name is in black list
        this.errorMessage = 'Not valid team name or team name is in black list';
      }
    },
  },
  computed: {
    ...mapGetters(
      {
        getActiveTeam: 'teams/getActiveTeam',
        getInitialSettings: 'user/getInitialSettings',
      }
    ),
  },
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>