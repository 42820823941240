<template>
  <component :is="tag" class="avatar" :class="this.parts.indexOf('uploader') !== -1 ? 'avatar--uploader' : ''">
    <div class="avatar__box" :style="'--size: ' + size + 'px'">
      <span class="avatar__img" :style="{'background-image': `url(${generateAvatar})`}"
            :title="getFullName" :data-user_id="getUserID()"></span>
      <input class="avatar__input" type="file" accept=".jpg,.jpeg,.png"
             @change="changeAvatar" v-if="parts.indexOf('uploader') !== -1"/>
    </div>
    <div class="avatar__name" v-if="parts.indexOf('name') !== -1">{{ getFullName }}</div>
  </component>
</template>

<script>
import {mapGetters} from 'vuex';
import {commonHelper} from "@/_helpers/common-helper";

export default {
  name: 'UserAvatar',
  props: {
    user: {
      type: Object,
      default: {},
    },
    parts: {
      type: Array,
      default: [],
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  data() {
    return {
      tag: this.parts.indexOf('uploader') !== -1 ? 'label' : 'div',
    }
  },
  methods: {
    getUser() {
      return this.user && this.user.avatar ? this.user : this.getInitialSettings.user;
    },
    getUserID() {
      let user = this.getUser();
      return user.user_id || null;
    },
    getUserName() {
      let user = this.getUser();
      if ( user ) {
        if( user.first_name && user.last_name ) {
          return `${user.first_name} ${user.last_name}`;
        }
      }
      return 'John Doe';
    },
    changeAvatar(e) {
      let user    = this.getUser(),
          avatars = document.querySelectorAll( '.avatar__img[data-user_id="' + user.user_id + '"]' ),
          file    = e.target.files[0],
          reader  = new FileReader();

      if ( file ) {
        const maxAllowedSize = 0.5 * 1024 * 1024; // maximum allowed size in bytes: 0.5Mb
        if ( file.size > maxAllowedSize ) {
          e.target.value = null;
          alert( 'Maximum allowed file size: 0.5Mb' );
          return false;
        }

        for ( let avatar of avatars ) {
          avatar.style.backgroundImage = 'url(' + URL.createObjectURL( file ) + ')';
        }
        reader.readAsDataURL(file);
      }
    },
  },
  computed: {
    ...mapGetters(
      {
        getInitialSettings: 'user/getInitialSettings',
      }
    ),
    generateAvatar() {
      let user = this.getUser();
      if( ! user.avatar_is_gravatar && user.avatar.includes( 'https://secure.gravatar.com/avatar/' ) ) {
        let shortName = commonHelper.getShortName(this.getUserName()),
            color = commonHelper.getGeneratedColor(this.getUserName());

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        canvas.width  = this.size;
        canvas.height = this.size;

        // Draw background
        context.fillStyle = color || 'black';
        context.fillRect(0, 0, canvas.width, canvas.height);

        // Draw text
        context.font = 'normal ' + ( this.size / 2.65 ) + 'px Poppins, Helvetica, Arial, sans-serif';
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText( shortName, canvas.width / 2, canvas.height / 2 + ( this.size / 20 ) );

        return canvas.toDataURL('image/png');
      } else {
        return user.avatar;
      }
    },
    getFullName() {
      return this.getUserName();
    },
  },
}
</script>

<style lang="postcss">
@import "styles.css";
</style>