<template>
  <div class="tooltip" :class="`tooltip_${tooltipType}`">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    tooltipType: {
      type: String,
      default: 'left'
    }
  }
}
</script>

<style lang="postcss">
@import "styles.css";
</style>