<template>
  <div v-if="errors.length" class="add-comment__error mb1">
    <div class="mb1" v-for="error in errors">
      {{ error }}
    </div>
  </div>
  <div class="add-comment flex jcsb aic">
    <UserAvatar v-if="showAvatar" :size="40" />
    <div class="add-comment__input-wrap flex fdr" :class="[!showAvatar ? 'add-comment__input-wrap_no-margin' : '']">
      <input v-model="content" class="add-comment__input" name="comment" type="text" placeholder="Add a comment">
      <div class="add-comment__controls flex aic">
        <label class="add-comment__files-upload">
          <input type="file" ref="uploadedFiles" multiple v-on:change="handleFilesUpload"/>
        </label>
        <button :disabled="processed" :class="processed ? 'is-load' : ''" @click="submitComment"
                class="button add-comment__send-comment"></button>
      </div>
    </div>
  </div>
  <div v-if="files.length" class="add-comment__attached-files mt1">
    Attached files: {{ files.length }}
  </div>
</template>

<script>
import apiService from '../../_services/api.service';
import {mapActions, mapMutations} from 'vuex';
import config from 'config';
import UserAvatar from "@/components/user-avatar/UserAvatar";

export default {
  name: 'AddComment',
  components: {
    UserAvatar,
  },
  props: {
    taskId: {
      required: true,
    },
    replyTo: {},
    showAvatar: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    return {
      files: '',
      content: '',
      errors: [],
      processed: false
    }
  },
  methods: {
    ...mapActions(
      {
        fetchComments: 'comments/fetchComments'
      }
    ),
    handleFilesUpload(e) {
      this.files = this.$refs.uploadedFiles.files;
    },
    submitComment() {
      this.errors = [];

      this.validateData();

      if (this.errors.length) {
        return false;
      }

      let formData = new FormData();

      formData.append("task_id", this.taskId);
      formData.append("comment_content", this.content);
      if (this.replyTo) {
        formData.append("comment_parent_id", this.replyTo);
      }

      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];

        formData.append(`uploaded_files[${i}]`, file);
      }

      this.processed = true;
      apiService.post('comments/create', formData).then(response => {
        this.content = "";
        this.files = "";
        this.fetchComments();
      }).catch(error => {
        let {data, status} = error.response;
        if (status === 422) {
          for (let index in data) {
            if (Array.isArray(data[index])) {
              data[index].forEach(item => {
                this.errors.push(item);
              })
            } else {
              this.errors.push(data[index]);
            }
          }
        } else {
          this.errors.push("Internal server error");
        }
      }).finally(() => {
        this.processed = false;
      })
    },
    validateData() {
      let maxUploadFilesSizeMB = config.maxUploadFilesSizeMB;
      let uploadedFilesSize = 0;

      if (this.content.trim() === '') {
        this.errors.push("Comment is required");
      }
      if (this.files.length) {
        this.files.forEach(file => {
          uploadedFilesSize += file.size;
        })
        if (uploadedFilesSize > maxUploadFilesSizeMB * 1024 * 1024) {
          this.errors.push(`Uploaded files size limit is ${maxUploadFilesSizeMB} MB`);
        }
      }
    },
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>