<template>
  <form class="grid" enctype="multipart/form-data" action="" method="post" @submit.prevent="saveProfile">
    <div class="grid__main">
      <div class="grid__col">
        <h3 class="grid__title">My Profile</h3>
      </div>
      <div class="grid__col">
        <UserAvatar :parts="['uploader','name']" :size="120" />
      </div>
      <div class="grid__col">
        <div class="grid__input--title">Detail Information:</div>
      </div>
      <div class="grid__col grid__col--half">
        <label class="grid__input--label" for="first-name">First Name</label>
        <input class="grid__input" name="first_name" type="text" id="first-name" minlength="2"
               placeholder="John" v-model="user.first_name" required>
      </div>
      <div class="grid__col grid__col--half">
        <label class="grid__input--label" for="last-name">Last Name</label>
        <input class="grid__input" name="last_name" type="text" id="last-name" minlength="2"
               placeholder="Doe" v-model="user.last_name" required>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="user-email">Email</label>
        <input class="grid__input" name="user_email" type="email" id="user-email"
               placeholder="john.doe@example.com" v-model="user.user_email" required>
      </div>
      <div class="grid__col">
        <div class="grid__input--title">
          {{ !user.profile_is_filled ? 'Set a password:' : 'Change password:' }}
        </div>
      </div>
      <div class="grid__col" v-if="user.profile_is_filled">
        <label class="grid__input--label" for="user-old-pwd">Old Password</label>
        <div class="grid__field">
          <input class="grid__input" name="user_pass" id="user-old-pwd"
                 placeholder="Old password" minlength="12" v-model="pwd"
                 :type="pwdOldType"
                 :class="pwdIsEmpty ? 'is-invalid' : ''">
          <span class="grid__icon" @click="switchVisibility('pwdOldType')">
            <i class="icon icon--eye" :class="pwdOldType === 'password' ? 'icon--eye' : 'icon--eye-close'"></i>
          </span>
        </div>
      </div>
      <PasswordSetter @update-password="onUpdatePassword"/>
    </div>
    <div class="grid__footer flex jcfs">
      <button class="button mla" type="submit" :class="submitted ? 'is-load' : ''" :disabled="submitted">Save & Close</button>
    </div>
  </form>
</template>

<script>
import apiService from '@/_services/api.service';
import Modal from '@/components/modal/Modal';
import UserAvatar from '@/components/user-avatar/UserAvatar';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import PasswordSetter from "@/components/password-setter/PasswordSetter";

export default {
  name: 'ModalProfile',
  components: {
    Modal,
    UserAvatar,
    PasswordSetter,
  },
  data() {
    return {
      submitted: false,
      user: null,
      pwd: null,
      userPass: null,
      userPassConfirm: null,
      pwdIsEmpty: null,
      pwdIsDiff: null,
      pwdOldType: 'password',
      pwdNewType: 'password',
    };
  },
  created() {
    this.user = this.getInitialSettings.user;
  },
  computed: {
    ...mapGetters(
      {
        getInitialSettings: 'user/getInitialSettings',
      }
    ),
  },
  methods: {
    ...mapActions(
      {
        fetchTasks: 'tasks/fetchTasks',
        fetchTeams: 'teams/fetchTeams',
        fetchInitialSettings: 'user/fetchInitialSettings',
      }
    ),
    onUpdatePassword( data ) {
      for ( let key in data ) {
        this[key] = data[key];
      }
    },
    switchVisibility(type) {
      this[type] = this[type] === 'password' ? 'text' : 'password';
    },
    saveProfile(e) {
      this.pwdIsDiff = this.userPass !== this.userPassConfirm;
      if( this.pwdIsDiff ) {
        return false;
      }
      this.submitted = true;

      let formData = new FormData( e.target );

      // add avatar
      let files = document.querySelector('.avatar__input').files;
      Array.from( files ).forEach( file => {
        formData.append( 'avatar', file );
      } );

      apiService.post( 'users/save_profile', formData )
        .then( response => {
          let data = response.data;
          if( data ) {
            if (response.data.profile_is_filled) {
              this.fetchInitialSettings();
              this.fetchTeams();
              this.fetchTasks();
              this.$parent.$emit('close');
            }
            this.user = data;
          }
        } )
        .finally( () => {
          this.submitted = false;
        } );
    },
  },
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>