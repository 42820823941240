<template>
  <div class="modal-invite-people">
    <div class="grid">
      <div class="grid__main">
        <div class="grid__col">
          <div class="grid__title text-center">
            Invite people
          </div>
        </div>
        <div class="grid__col">
          <div class="modal-invite-people__description text-center">Copy the link and send it to the person you want to
            add to the
            system.
          </div>
        </div>
        <div class="grid__col">
          <div class="modal-invite-people__link-wrap flex aic">
            <span class="modal-invite-people__link">{{ inviteLink }}</span>
            <button @click.prevent="copyToClipboard($event, inviteLink)" class="btn btn--icon flex aic jcc">
              <i class="icon icon--copy"></i>
            </button>
          </div>
        </div>
        <div v-for="user in users" class="grid__col">
          <div class="modal-invite-people__person flex jcsb aic">
            <div class="modal-invite-people__person-person flex aic">
              <UserAvatar :user="user" :size="48" />
              <div class="modal-invite-people__person-info">
                <div class="modal-invite-people__person-name">
                  {{ user.first_name || user.last_name ? `${user.first_name} ${user.last_name}` : 'John Doe' }}
                </div>
                <div class="modal-invite-people__person-email">
                  {{ `${user.user_email}` }}
                </div>
              </div>
            </div>
            <div class="modal-invite-people__person-role">{{ `${user.role_label}` }}</div>
          </div>
        </div>

        <div class="modal-invite-people__subscribe-banner flex jcsb aic w100">
          <div class="modal-invite-people__subscribe-banner-price flex aic">
            $5 per user monthly
          </div>
          <button class="button modal-invite-people__subscribe-btn">Subscribe</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import base64_encode from 'locutus/php/url/base64_encode';
import {commonHelper} from '@/_helpers/common-helper';
import UserAvatar from "@/components/user-avatar/UserAvatar";

export default {
  name: 'ModalInvitePeople',
  components: {
    UserAvatar,
  },
  methods: {
    copyToClipboard(e, copy_data, replacement) {
      return commonHelper.copyToClipboard(e, copy_data, replacement);
    },
  },
  computed: {
    ...mapGetters(
      {
        getActiveTeam: 'teams/getActiveTeam',
        getInitialSettings: 'user/getInitialSettings'
      }
    ),
    inviteLink() {
      let inviteLink = '';
      const team = this.getActiveTeam;
      const initialSettings = this.getInitialSettings;
      const inviteData = {
        fullName: `${initialSettings.user.first_name} ${initialSettings.user.last_name}`,
        teamName: team.team_name
      };
      if (team && team.invite_token.length) {
        const url = new URL(window.location.protocol + "//" + window.location.host);
        url.searchParams.set('invite_token', team.invite_token);
        url.searchParams.set('data', base64_encode(JSON.stringify(inviteData)));
        return url.href;
      }
      return inviteLink;
    },
    users() {
      const team = this.getActiveTeam;
      if (team && team.assigned_users) {
        return team.assigned_users;
      }
      return [];
    }
  },
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>