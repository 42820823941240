<template>
  <span class="task-item__user-data flex jcc aic" :class="userInfoClasses">
    {{ userInfoValue }}
  </span>
</template>

<script>
export default {
  props: {
    userInfoItem: Object,
  },

  methods: {
    /**
     *
     * @returns {string}
     * @param countryName
     */
    getCountryClassByCountryName(countryName) {
      let countryClass = 'unknown_country'; //Need add icon for unknown country

      const definitions = {
        'United States': 'usa',
        'Russian Federation': 'russia',
        'Poland': 'poland',
        'Ukraine': 'ukraine'
        //TODO all countries
      }
      for (const [key, value] of Object.entries(definitions)) {
        if (key === countryName) {
          countryClass = value;
          break;
        }
      }

      return countryClass;
    },

    /**
     *
     * @param browserName
     * @returns {string}
     */
    getBrowserClassByBrowserName(browserName) {
      let browserClass = 'unknown_browser'; //Need add icon for unknown browser

      const definitions = {
        'Chrome': 'google_chrome',
        'Safari': 'safari',
        'Opera': 'opera',
        'Firefox': 'firefox',
        'Explorer': 'ie'
        //TODO all browsers
      }
      for (const [key, value] of Object.entries(definitions)) {
        if (key === browserName) {
          browserClass = value;
          break;
        }
      }

      return browserClass;

    },

    /**
     *
     * @param OSName
     * @returns {string}
     */
    getOSClassByOSName(OSName) {
      let OSClass = 'unknown_os'; //Need add icon for unknown OS

      const definitions = {
        'Windows': 'windows',
        'Mac': 'mac',
        'Linux': 'linux',
        'Android': 'android'
        //TODO all OS
      }
      for (const [key, value] of Object.entries(definitions)) {
        if (key === OSName) {
          OSClass = value;
          break;
        }
      }

      return OSClass;
    }
  },

  computed: {
    userInfoClasses() {
      let classes = [];
      switch (this.userInfoItem.slug) {
        case 'user_browser':
          let browserClass = this.getBrowserClassByBrowserName(this.userInfoItem.name);
          classes.push('task-item__user-data--browser');
          classes.push(`task-item__user-data--browser-${browserClass}`);
          break;
        case 'user_os':
          let userOSClass = this.getOSClassByOSName(this.userInfoItem.name);
          classes.push('task-item__user-data--os');
          classes.push(`task-item__user-data--os-${userOSClass}`);
          break;
        case 'user_resolution':
          classes.push('task-item__user-data--resolution');
          classes.push(`task-item__user-data--resolution-${this.userInfoItem.type}`);
          break;
        case 'user_warnings':
          classes.push('task-item__user-data--warnings');
          break;
        case 'user_errors':
          classes.push('task-item__user-data--errors');
          break;
        case 'user_ip':
          let userCountryClass = this.getCountryClassByCountryName(this.userInfoItem.country);
          classes.push('task-item__user-data--ip');
          classes.push(`task-item__user-data--ip-${userCountryClass}`);
          break;
        case 'user_scale':
          classes.push('task-item__user-data--scale');
          if (this.userInfoItem.scale > 100) {
            classes.push('task-item__user-data--scale-plus');
          } else if (this.userInfoItem.scale < 100) {
            classes.push('task-item__user-data--scale-minus');
          }
          break;
        case 'user_name':
          classes.push('task-item__user-data--name');
          break;
      }
      return classes.join(' ');
    },

    userInfoValue() {
      let value = '';

      switch (this.userInfoItem.slug) {
        case 'user_browser':
          value = this.userInfoItem.version;
          break;
        case 'user_resolution':
          value = this.userInfoItem.resolution;
          break;
        case 'user_warnings':
          value = this.userInfoItem.count;
          break;
        case 'user_errors':
          value = this.userInfoItem.count;
          break;
        case 'user_ip':
          value = this.userInfoItem.ip;
          break;
        case 'user_scale':
          value = this.userInfoItem.scale;
          break;
        case 'user_name':
          value = this.userInfoItem.name;
          break;
      }

      return value;
    }
  }
}
</script>

<style lang="postcss">
@import "styles.css";
</style>