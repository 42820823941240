<template>
  <div class="task-description">
    <div class="task-description__header mb1">
      Description:
    </div>
    <div class="task-description__description">
      <span v-html="description"> </span>
    </div>
    <div class="task-description__uploaded-files">
      <uploadedFiles :uploadedFiles="uploadedFiles" />
    </div>
  </div>
</template>

<script>
import UploadedFiles from '../uploaded-files/UploadedFiles';
export default {
  name: 'TaskDescription',
  components: {UploadedFiles},
  props: {
    description: {
      required: true,
    },
    uploadedFiles: {
      type: Array
    }
  },
  computed: {
    getExtensionClass() {
      return type => {
        let class_ = '';
        switch (type) {
          case 'pdf':
            class_ = type
            break;
        }
        return `file_icon_${class_}`;
      }
    }
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>