<template>
  <div class="qat__sidebar sidebar flex fdc jcfs aistr" :class="getNotifications.length ? 'sidebar--noticed' : ''">
    <div class="sidebar__header flex jcsb aic">
      <div class="sidebar__header-container flex jcfe">
        <router-link to="/" class="sidebar__logo flex jcfs aic mra ml0">
          <img class="sidebar__logo-img db" src="../../assets/images/logo-qat.png" alt="QA Tool">
        </router-link>
      </div>
      <div class="sidebar__add flex m0">
        <button @click="openModalProjects" class="button flex jcc aic">Add Project</button>
      </div>
    </div>
    <ProjectsList/>
    <div class="sidebar__widgets">
      <UsersManage/>
      <Person/>
    </div>
  </div>
  <Modal :isVisible="isModalVisible" :key="'ModalProjects'" :isClosable="true" @close="isModalVisible = false">
    <ModalProjects/>
  </Modal>
</template>

<script>
import Modal from '@/components/modal/Modal';
import ModalProjects from '@/components/modal-projects/ModalProjects';
import Person from '@/components/person/Person';
import ProjectsList from '@/components/projects-list/ProjectsList';
import UsersManage from '../users-manage/UsersManage';
import {mapGetters} from 'vuex';

export default {
  name: 'TasksSidebar',
  data: () => {
    return {
      isModalVisible: false,
    };
  },
  components: {
    UsersManage,
    ProjectsList,
    Person,
    Modal,
    ModalProjects,
  },
  methods: {
    openModalProjects() {
      this.isModalVisible = true;
    },
  },
  computed: {
    ...mapGetters({
      getNotifications: 'notifications/getNotifications',
    }),
  },
};
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>