<template>
  <div class="errors-container">
    <div class="errors-container__active-image" :class="randomClass()">
      <img :src="images[activeImage]">
    </div>
    <div v-if="images.length > 1" class="errors-container__gallery-container">
      <div class="errors-container__gallery-wrap">
        <div class="errors-container__gallery">
          <ul :style="{marginLeft: offset + 'px'}" class="errors-container__images">
            <li @click="selectImage(index)" :class="{active: index === activeImage}"
                v-for="(image, index) in images">
              <img class="unselectable" :src="image">
            </li>
          </ul>
          <template v-if="images.length > visibleSlides">
            <span @click="slideLeft" class="icon errors-container__slide-left"></span>
            <span @click="slideRight" class="icon errors-container__slide-right"></span>
          </template>
        </div>
      </div>
    </div>
    <div class="errors-container__console">
      <div class="console flex jcsb">
        <div @click="toggleErrors" v-if="consoleData" class="console__open-console flex aic">
          <span class="console__open-console-label">Open console</span>
          <span class="console__open-console-button"
                :class="[showErrors ? 'console__open-console-button_open' : '']"></span>
        </div>
        <div v-if="images.length > 1" class="console__selected-slide">
          {{ `${activeImage + 1}/${images.length}` }}
        </div>
        <div class="console__errors flex fww">
          <span class="icon console__errors-warning flex jcc aic">{{ getWarningsCount }}</span>
          <span class="icon console__errors-error flex jcc aic">{{ getErrorsCount }}</span>
        </div>
      </div>
      <div v-if="showErrors" class="errors-container__error-list">
        <table class="w100">
          <thead>
          <tr>
            <td><strong>Type</strong></td>
            <td><strong>Message</strong></td>
            <td><strong>Url</strong></td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="{log} in consoleData">
            <td>{{ log.type }}</td>
            <td>{{ log.message }}</td>
            <td>{{ log.url }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'errors-container',
  props: {
    images: {
      type: Array,
      required: true
    },
    consoleData: {
      type: Array,
      required: false
    }
  },
  data: () => {
    return {
      activeImage: 0,
      visibleSlides: 5,
      width: 56,
      offset: 0,
      showErrors: false
    }
  },
  methods: {
    selectImage(index) {
      this.activeImage = index;
    },
    slideLeft() {
      let offset = this.offset;
      offset += this.width * this.visibleSlides;
      this.offset = Math.min(offset, 0);
    },
    slideRight() {
      let offset = this.offset;
      offset -= this.width * this.visibleSlides;
      offset = Math.max(offset, -this.width * (this.images.length - this.visibleSlides));
      this.offset = offset;
    },
    toggleErrors() {
      this.showErrors = !this.showErrors;
    },
    randomClass() {
      return (Math.random() + 1).toString(36).substring(7);
    }
  },
  computed: {
    getErrorsCount() {
      let errorsCount = 0;
      if (!this.consoleData || !this.consoleData.length) {
        return 0;
      }
      this.consoleData.forEach(item => {
        if (item.log.type === 'Error') {
          errorsCount++;
        }
      })
      return errorsCount;
    },
    getWarningsCount() {
      let errorsCount = 0;
      if (!this.consoleData || !this.consoleData.length) {
        return 0;
      }
      this.consoleData.forEach(item => {
        if (item.log.type === 'Warn') {
          errorsCount++;
        }
      })
      return errorsCount;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "styles.css";
</style>