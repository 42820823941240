// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/file-upload.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/file.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/file-pdf.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ".uploaded-files__list{margin:0;padding:0;list-style:none}.uploaded-files__file-link{font-family:var(--font-default);font-size:12px;line-height:22px;font-style:normal;font-weight:400;text-decoration:none;color:var(--blue);margin-left:8px}.uploaded-files__file-upload{text-decoration:none;display:block}.uploaded-files__file-upload:before{width:16px;height:16px;content:\"\";display:block;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%}.file:before{content:\"\";display:block;width:24px;height:24px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 50%}.file_icon_pdf:before{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat 50%}", ""]);
// Exports
module.exports = exports;
