<template>
  <form class="grid">
    <div class="grid__main">
      <div class="grid__col">
        <h3 class="grid__title">Add a new project</h3>
      </div>
      <div class="grid__col">
        <button @click.prevent="clickupAuthorise" v-if="!clickupIsConnected" class="btn btn--modal">
          <i class="icon icon--left icon--clickup"></i> Allow
        </button>
        <ProjectsRemote v-else/>
      </div>
      <div class="grid__div" data-content="Or"></div>
      <div class="grid__col">
        <label class="grid__input--label" for="project-name">Name</label>
        <input v-model="projectName" class="grid__input" id="project-name" placeholder="Enter project name" required>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="project-name">Prefix</label>
        <input v-model="projectPrefix" maxlength="12"
               class="grid__input" id="project-prefix" placeholder="Enter project prefix" required>
        <p class="grid__error">{{ errorMessage }}</p>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="domain">Add Domain</label>
        <input v-model="domain" class="grid__input" id="domain" placeholder="qatool.com">
      </div>
      <div class="grid__col">
        <button :disabled="!canSubmit" :class="submitted ? 'is-load' : ''" @click.prevent="handleSubmit"
                class="btn btn--modal btn--submit">ADD NEW PROJECT
        </button>
      </div>
      <div class="grid__div" data-content="Coming soon"></div>
      <div class="grid__col">
        <button class="btn btn--modal" disabled><i class="icon icon--left icon--asana"></i> Asana</button>
      </div>
      <div class="grid__col">
        <button class="btn btn--modal" disabled><i class="icon icon--left icon--jira"></i> Jira</button>
      </div>
    </div>
  </form>
</template>

<script>
import apiService from "@/_services/api.service";
import Modal from '@/components/modal/Modal';
import ProjectsRemote from '@/components/projects-remote/ProjectsRemote';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import store from '@/_store';
import {projectsService} from '@/_services/projects.service';

export default {
  name: 'ModalProjects',
  components: {
    Modal,
    ProjectsRemote,
  },
  data() {
    return {
      projectName: '',
      projectPrefix: '',
      domain: '',
      submitted: false,
      errorMessage: '',
    }
  },
  methods: {
    ...mapActions(
      {
        fetchProjects: 'projects/fetchProjects',
      }
    ),
    handleSubmit() {
      this.submitted = true;
      const initialSettings = store.getters['user/getInitialSettings'];
      const teamId = initialSettings.team.team_id;
      (async () => {
        await projectsService.createProject(this.projectName, this.domain, teamId, this.projectPrefix);
        await this.fetchProjects();
        this.submitted = false;
        this.$parent.$emit('close');
      })();
    },
    clickupAuthorise() {
      window.location.href = `${this.clickupUrl}?client_id=${this.clickupClientId}&redirect_uri=${window.location.href}`;
    },
  },
  watch: {
    projectPrefix(val) {
      this.errorMessage = '';
      if ( val ) {
        this.projectPrefix = val.toUpperCase();
        if( /[^a-zA-Z0-9]/.test( val ) ) {
          this.projectPrefix = val.slice( 0, -1 );
        }

        if( this.getProjects.projects ) {
          this.getProjects.projects.forEach( ( project ) => {
            if( project.project_prefix === this.projectPrefix ) {
              this.errorMessage = 'The project prefix must be unique';
              return false;
            }
          } )
        }
      }
    }
  },
  computed: {
    ...mapGetters(
      {
        getActiveTeam: 'teams/getActiveTeam',
        getInitialSettings: 'user/getInitialSettings',
        getProjects: 'projects/getProjects',
      }
    ),
    canSubmit() {
      return !this.submitted && this.projectName.length > 0 && this.projectPrefix.length > 0 && this.errorMessage === ''
    },
    clickupIsConnected() {
      const team = this.getActiveTeam;
      return team.integrations.clickup.is_authorised;
    },
    clickupUrl() {
      const team = this.getActiveTeam;
      return team.integrations.clickup.api_url;
    },
    clickupClientId() {
      const team = this.getActiveTeam;
      return team.integrations.clickup.client_id;
    },
  }
}
</script>

<style lang="postcss">
@import "styles.css";
</style>