<template>
  <form class="grid">
    <div class="grid__main">
      <div class="grid__col">
        <h3 class="grid__title">Edit project</h3>
      </div>
      <div class="grid__col" v-if="!clickupIsConnected">
        <button @click.prevent="clickupAuthorise" class="btn btn--modal">
          <i class="icon icon--left icon--clickup"></i> Allow
        </button>
      </div>
      <div class="grid__col">
        <div class="projects" v-if="clickupIsConnected" :class="!remoteProjectsIsLoaded ? 'projects--load' : ''">
          <div class="projects__team" v-for="(team, i) in filteredRemoteProjects">
            <h4>{{ team.name }}</h4>
            <div class="projects__item flex aic jcsb"
                 v-for="(remoteProject) in team.spaces">
              <h5>{{ remoteProject.project_name }}</h5>
              <button @click.prevent="switchLinkProject(remoteProject)">
                {{ remoteProject.linked ? 'unlink' : 'link' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="edit-project-name">Name</label>
        <input v-model="projectName" class="grid__input" id="edit-project-name" placeholder="Enter project name" required>
      </div>
      <div class="grid__col">
        <label class="grid__input--label" for="edit-project-name">Prefix</label>
        <input v-model="projectPrefix" @input="unifyPrefix" maxlength="12"
               class="grid__input" id="edit-project-prefix" placeholder="Enter project prefix" required>
        <p class="grid__error">{{ errorMessage }}</p>
      </div>
      <div class="grid__col">
        <button :disabled="!canSubmit" :class="editProcessed ? 'is-load' : ''" @click.prevent="editSubmit"
                class="btn btn--modal btn--submit">Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

export default {
  name: 'ModalEditProject',
  async created() {
    if (this.clickupIsConnected) {
      await this.fetchRemoteProjects();
    }
    this.remoteProjectsIsLoaded = true;
    this.projectName = this.project.project_name;
    this.projectPrefix = this.project.project_prefix;
    this.oldProjectPrefix = this.project.project_prefix;
  },
  props: {
    project: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      remoteProjectsIsLoaded: false,
      projectName: '',
      projectPrefix: '',
      oldProjectPrefix: '',
      editProcessed: false,
      errorMessage: '',
    }
  },
  methods: {
    ...mapActions(
      {
        fetchRemoteProjects: 'projects/fetchRemoteProjects',
        updateProject: 'projects/updateProject',
        fetchProjects: 'projects/fetchProjects',
      }
    ),
    clickupAuthorise() {
      window.location.href = `${this.clickupUrl}?client_id=${this.clickupClientId}&redirect_uri=${window.location.href}`;
    },
    switchLinkProject(remoteProject) {
      remoteProject.linked = remoteProject.linked ? null : remoteProject.remote_id;
      remoteProject.project_id = remoteProject.linked ? this.project.project_id : null;

      for (const team of this.filteredRemoteProjects) {
        for (const project of team.spaces) {
          if (project.remote_id !== remoteProject.remote_id) {
            project.linked = null;
          }
        }
      }
    },
    async editSubmit() {
      let linkedProject = null;
      this.editProcessed = true;
      for (const team of this.filteredRemoteProjects) {
        for (const project of team.spaces) {
          if (project.linked) {
            linkedProject = project;
            break;
          }
        }
      }
      const params = {};
      params.remote_id = linkedProject ? linkedProject.remote_id : '';
      params.project_id = this.project.project_id;
      if (this.projectName) {
        params.project_name = this.projectName;
      }

      if (this.projectPrefix) {
        params.project_prefix = this.projectPrefix;
      }
      await this.updateProject(params);
      await this.fetchRemoteProjects();
      await this.fetchProjects();
      this.editProcessed = false;
    }
  },
  computed: {
    ...mapGetters(
      {
        getActiveTeam: 'teams/getActiveTeam',
        getRemoteProjects: 'projects/getRemoteProjects',
        getProjects: 'projects/getProjects',
      },
    ),
    clickupIsConnected() {
      const team = this.getActiveTeam;
      return team.integrations.clickup.is_authorised;
    },
    clickupUrl() {
      const team = this.getActiveTeam;
      return team.integrations.clickup.api_url;
    },
    clickupClientId() {
      const team = this.getActiveTeam;
      return team.integrations.clickup.client_id;
    },
    canSubmit() {
      return !this.editProcessed && this.projectName && this.projectPrefix;
    },
    filteredRemoteProjects() {
      const teams = this.getRemoteProjects;
      teams.forEach( ( team, index ) => {
        let filteredProjects = [];
        team.spaces.forEach( space => {
          if (space.project_id + '' === this.project.project_id + '' || !space.linked) {
            filteredProjects.push(space);
          }
        })
        teams[index].spaces = filteredProjects;
      })
      return teams;
    },
    unifyPrefix() {
      this.errorMessage = '';

      if( /[^a-zA-Z0-9]/.test( this.projectPrefix ) ) {
        this.projectPrefix = this.projectPrefix.slice( 0, -1 );
      }

      if( this.getProjects.projects ) {
        this.getProjects.projects.forEach( ( project ) => {
          if( project.project_prefix === this.projectPrefix && this.oldProjectPrefix !== this.projectPrefix ) {
            this.errorMessage = 'The project prefix must be unique';
          }
        } )
      }
    },
  },
  watch: {
    projectPrefix(val) {
      if (val) {
        this.projectPrefix = val.toUpperCase();
      }
    }
  },
};
</script>

<style lang="postcss">
@import "styles.css";
</style>